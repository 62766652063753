import { HeroCenter } from "./HeroImageHandlers"
import HeroBannerProps from "./HeroTypes"
import { HeroTitle } from "../Styling/Titles"
import { Ingress } from "../Styling/Body"
import { BackArrowButton } from "../Graphics/Arrow"
import getMainPath from "../Common/MainPaths"
import { toDisplayDate } from "../Common/DateConverter"
import ShareModal from "../Modals/ShareModal"

const HeroArticlePages = (props: HeroBannerProps) => {
  let date = props.createdAt || ""
  if (props.updatedAt !== undefined && props.updatedAt !== null) date = props.updatedAt
  if (props.createdAtModified !== undefined && props.createdAtModified !== null) date = props.createdAtModified

  const prettyDate = toDisplayDate(date)

  let returnText = ""
  let mainPath = ""

  if (props.href !== undefined && props.href !== null) {
    if (props.href.includes("aktuelt")) {
      returnText = "Aktuelt"
      mainPath = getMainPath("article")
    } else if (props.href.includes("tema")) {
      returnText = "Tema"
      mainPath = getMainPath("topics")
    } else if (props.href.includes("eiernytt")) {
      returnText = "Eiernytt"
      mainPath = "/minside/eiernytt"
    }
  }

  return (
    <>
      <header>
        <div className="max-w-240 mx-auto">
          <div>
            <div className=" text-center break-normal">
              <div className="hidden items-center sm:contents z-30 sm:mt-9">
                <BackArrowButton url={mainPath} title={returnText} />
              </div>
              <div className=" mt-14 sm:mt-9 max-w-176 mb-10 md:mb-20 px-5">
                <HeroTitle>{props.title}</HeroTitle>
              </div>
            </div>
            <div className="lg:max-w-116 mx-auto  ">
              <HeroCenter {...props} />
            </div>

            <div className="mx-auto  text-center max-w-screen">
              <div className="text-center px-5 mt-11 sm:mt-16 text-author-500 text-opacity-50">
                <p>
                  Av: {props.author}&nbsp; Foto: {props.image.imageCredits} <br /> Sist oppdatert: {prettyDate}
                  <br />
                  <ShareModal title={props.title} url={props.href} className="my-3" />
                </p>
              </div>
              <div className="px-4 pt-5 sm:pt-0 sm:px-0 max-w-xs sm:max-w-lg md:max-w-xl xl:max-w-84 mx-auto mt-8 sm:mt-16">
                <Ingress>{props.ingress}</Ingress>
              </div>
            </div>
          </div>
        </div>
      </header>
    </>
  )
}

export default HeroArticlePages
