import { graphql } from "gatsby"
import MDXRenderer from "gatsby-plugin-mdx/mdx-renderer"
import { useIsAuthenticated } from "@azure/msal-react"
import SEO, { getSeoProps } from "../components/Common/SEO"
import { RelatedSectionProps, RelatedSection } from "../components/Sections/RelatedSection"
import { Ingress } from "../components/Styling/Body"
import HeroArticlePages from "../components/Hero/HeroArticlePages"
import getBannerProps from "../components/Hero/getHeroBannerProps"
import { SortByDate } from "../components/Common/DateConverter"
import MyPageLayout from "../components/Layout/MyPageLayout"
import { MSRelatedSection } from "../components/Sections/MSRelatedSection"

/**
 * @description: Det skal vises tre relaterte artikler på hver artikkel side.
 * Hva mener vi med relevante artikler:
 * 1. Samme kategori som artikkelen.
 * 2. Samme tags dersom det finnes noe. (Fjen dersom ingen artikler finnes)
 * 3. Vise de siste artikler baset på created date. Nyeste artikler først.
 */
const MinSideTemplate = ({ location, data }) => {
  const isAuthenticated = useIsAuthenticated()

  const { contentfulArticle: content } = data

  let {
    allContentfulArticle: { nodes: allContent },
  } = data

  if (allContent !== null && allContent !== undefined) {
    // Show only articles on article pages.
    if (location.pathname.includes("aktuelt")) allContent = allContent.filter((x) => x.articleType === false)

    // Show only articles on article pages.
    if (location.pathname.includes("tema")) allContent = allContent.filter((x) => x.articleType === true)

    allContent = allContent.filter((x) => x.id !== content.id)

    // Find content within the same taxonomy: Category
    if (content.category != null) allContent = allContent.filter((x) => x.category?.id === content.category?.id)

    let relatedArticles = []
    if (allContent.length > 3) {
      // If more than 3 articles exist in the same category, sort by taxonomy TAGS
      if (content.tags != null) {
        const articleTags = []
        content.tags.forEach((tag) => {
          articleTags.push(tag.id)
        })

        const related = allContent.filter((d) => d.tags?.every((c) => articleTags.includes(c.id)))
        relatedArticles = related
      }

      if (relatedArticles.length < 3) {
        for (let i = 0; i < allContent.length; i++) {
          if (relatedArticles.length === 3) break

          const found = relatedArticles.some((r) => allContent[i].id === r.id)
          if (!found) relatedArticles.push(allContent[i])
        }
      }
      if (relatedArticles.length >= 3) {
        const size = 3
        relatedArticles = relatedArticles.slice(0, size)
      }

      allContent = relatedArticles
    }
    // Sort all related articles from new to old. Let GH override with createdAtModified
    allContent = SortByDate(allContent)
  }

  // Parse the content and create the props needed by SEO component.
  const helmetProps = getSeoProps(content, location, "article")

  // Parse the content, and get the needed banner props
  const heroBannerProps = getBannerProps(content, location.href)

  const relatedProps: RelatedSectionProps = {
    title: "Relaterte artikler",
    path: "nb/minside/eiernytt",
    data: allContent,
    headerUrl: "/eiernytt",
  }

  //   if (location.pathname.includes("aktuelt")) {
  //     relatedProps.title = "Relaterte artikler"
  //     relatedProps.path = "nb/aktuelt"
  //     relatedProps.headerUrl = "/aktuelt"
  //   } else if (location.pathname.includes("tema")) {
  //     relatedProps.title = "Relaterte temaartikler"
  //     relatedProps.path = "nb/tema"
  //     relatedProps.headerUrl = "/tema"
  //   }

  return (
    <>
      <SEO {...helmetProps} />
      <MyPageLayout title={""}>
        <main>
          <article>
            <div className="bg-white relative max-w-7xl mx-auto overflow-hidden sm:px-6 lg:px-8 ">
              <HeroArticlePages {...heroBannerProps} />

              <div className="mx-auto max-w-screen md:max-w-2xl px-5 md:px-8 lg:max-w-3xl  xl:max-w-116 mb-48">
                {content.public === false ? (
                  <>
                    <MDXRenderer>{content.body.childMdx.body}</MDXRenderer>

                    {/* <Ingress>{content.ingress.ingress}</Ingress> */}
                  </>
                ) : (
                  <></>
                )}
              </div>
            </div>
          </article>
        </main>
      </MyPageLayout>
      <MSRelatedSection {...relatedProps} />
    </>
  )
}

export default MinSideTemplate

export const articleQuery = graphql`
  query msArticlesQuery($slug: String!) {
    contentfulArticle(slug: { eq: $slug }, node_locale: { eq: "nb-NO" }) {
      id
      public
      contentful_id
      slug
      order
      node_locale
      metadataDescription
      subtitle
      title
      articleType
      updatedAt(formatString: "DD.MM.YYYY hh:mm:ss")
      createdAtModified(formatString: "DD.MM.YYYY hh:mm:ss")
      createdAt(formatString: "DD.MM.YYYY hh:mm:ss")
      author {
        name
        firstName
        lastName
        twitter
        twitterId
      }
      tags {
        id
        title
      }
      category {
        id
        category
        slug
      }
      videoUrl
      heroImage {
        id
        public_id
        context {
          custom {
            Credits
            alt
          }
        }
        url
        secure_url
        width
        height
        format
      }

      ingress {
        ingress
        childMdx {
          timeToRead
        }
      }
      body {
        childMdx {
          timeToRead
          body
        }
      }
    }
    allContentfulArticle(filter: { public: { eq: false }, node_locale: { eq: "nb-NO" }, articleType: { eq: false } }) {
      nodes {
        id
        public
        contentful_id
        title
        subtitle
        slug
        order
        articleType
        node_locale
        metadataDescription
        createdAt(formatString: "DD.MM.YYYY hh:mm:ss")
        updatedAt(formatString: "DD.MM.YYYY hh:mm:ss")
        createdAtModified(formatString: "DD.MM.YYYY hh:mm:ss")
        category {
          id
          category
          slug
        }
        tags {
          id
          title
        }
        videoUrl
        heroImage {
          id
          public_id
          context {
            custom {
              Credits
              alt
            }
          }
          url
          secure_url
          width
          height
          format
        }
        ingress {
          ingress
          childMdx {
            timeToRead
          }
        }
        body {
          childMdx {
            timeToRead
            body
          }
        }
        author {
          name
          firstName
          lastName
          twitter
          twitterId
        }
      }
    }
  }
`
